import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const options = [
  { value: 'Alamance', label: 'Alamance' },
  { value: 'Beaufort', label: 'Beaufort' },
  { value: 'Buncombe', label: 'Buncombe' },
  { value: 'Camden', label: 'Camden' },
  { value: 'Chatham', label: 'Chatham' },
  { value: 'Cherokee', label: 'Cherokee' },
  { value: 'Chowan', label: 'Chowan' },
  { value: 'Clay', label: 'Clay' },
  { value: 'Currituck', label: 'Currituck' },
  { value: 'Dare', label: 'Dare' },
  { value: 'Durham', label: 'Durham' },
  { value: 'Franklin', label: 'Franklin' },
  { value: 'Gates', label: 'Gates' },
  { value: 'Graham', label: 'Graham' },
  { value: 'Granville', label: 'Granville' },
  { value: 'Guilford', label: 'Guilford' },
  { value: 'Harnett', label: 'Harnett' },
  { value: 'Haywood', label: 'Haywood' },
  { value: 'Henderson', label: 'Henderson' },
  { value: 'Hyde', label: 'Hyde' },
  { value: 'Jackson', label: 'Jackson' },
  { value: 'Johnston', label: 'Johnston' },
  { value: 'Lee', label: 'Lee' },
  { value: 'Macon', label: 'Macon' },
  { value: 'Martin', label: 'Martin' },
  { value: 'Mecklenburg', label: 'Mecklenburg' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Pasquotank', label: 'Pasquotank' },
  { value: 'Perquimans', label: 'Perquimans' },
  { value: 'Person', label: 'Person' },
  { value: 'Polk', label: 'Polk' },
  { value: 'Swain', label: 'Swain' },
  { value: 'Transylvania', label: 'Transylvania' },
  { value: 'Tyrrell', label: 'Tyrrell' },
  { value: 'Vance', label: 'Vance' },
  { value: 'Wake', label: 'Wake' },
  { value: 'Warren', label: 'Warren' },
  { value: 'Washington', label: 'Washington' }
  ]

  const type = [
    { value: 'Civil', label: 'Civil' },
    { value: 'Criminal', label: 'Criminal' }
  ]


function Home() {
  const [selectedOption, setSelectedOption] = useState("none");
  const [selectedOptionB, setSelectedOptionB] = useState("none");
  const handleTypeSelect = e => {
    setSelectedOption(e.value);
  };
  const handleTypeSelectB = e => {
    setSelectedOptionB(e.value);
  };
const resultsUrl = "/results?county=" + selectedOption + '&type=' + selectedOptionB;
const navigate = useNavigate();
const handleSubmit = event => {
  event.preventDefault();
  navigate(resultsUrl);
};

  return (
<main id="content-container" className="content-container" role="main">
<div>
    <div id="block-breadcrumbs">
  
    
    <nav className="breadcrumb" role="navigation" aria-labelledby="system-breadcrumb">
        <div className="breadcrumbs">
            <div className="wrapper">
                <ol itemScope="" itemType="http://schema.org/BreadcrumbList">
                                            <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                            <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                                                                    <a href="https://www.nccourts.gov"><span itemProp="name" title="Go back to: Home">Home</span></a>
                                       
                            </span>
                            <meta itemProp="position" content="1" loop-length="3"/>
                        </li>
                                            <li className="parent" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                            <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                                                                    <a href="https://www.nccourts.gov/court-dates"><span itemProp="name" title="Go back to: Court Dates">Court Dates</span></a>
                                       
                            </span>
                            <meta itemProp="position" content="2" loop-length="3"/>
                        </li>
                                            <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                            <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                                                                    <span itemProp="name" title="Currently viewing: Civil & Criminal Calendars"> Civil & Criminal Calendars</span>
                                   
                            </span>
                            <meta itemProp="position" content="3" loop-length="3"/>
                        </li>
                                    </ol>
            </div>
        </div>
    </nav>

  </div>

  </div>
  <article itemScope itemType="http://schema.org/WebPage">
    <header className="title title--primary" >
      <div className="wrapper">
        <h1 className="title__label" itemProp="headline">Civil and Criminal Calendars</h1>
        <p className="title__description" itemProp="description">Select a county and calendar type from the pull down menu to access calendars.</p>
      </div>
    </header>
    <div id="block-tyler-vs-legacy">  
  <div className="zone" itemProp="mainContentOfPage">
<section className="band" data-columns="1" data-theme="default"><div className="wrapper">
<div className="band__part">
<div className="alert alert--primary" role="alert">
<p><b>These calendars are for <a href="https://www.nccourts.gov/ecourts">eCourts counties only</a>.</b></p><p>You may also <a href="https://portal-nc.tylertech.cloud/Portal/">search by name, attorney, case number, and more</a>.</p><p>For all other counties, go to <a href="https://www.nccourts.gov/court-dates">Court Dates</a> for calendars and search options.</p>
</div>
</div>
</div>
</section></div>


  </div>
    <div className="zones">
      <div className="zone" itemProp="mainContentOfPage">
	          <div className="band">
          <div className="wrapper">
            <div className="band__parts">
              <div className="band__part">
<Form onSubmit={handleSubmit} className="form form--filter-panel" id="calendarSelect">
  <fieldset>
  <div className="js-form-item form-item js-form-type-select form-item-field-form-type-target-id js-form-item-field-form-type-target-id formblock">
  <div>
  <label type="select" htmlFor="county-list" className="formblock__label">County</label>
    <Select required name="county-list" id="countyname" options={options}
        onChange={handleTypeSelect}
        value={options.filter(function(option) {
          return option.value === selectedOption;
        })} placeholder="-- Select County --" / >
</div>
<br/>
<div>
<label type="select" htmlFor="type-list" className="formblock__label">Calendar Type</label>
    <Select required name="type-list" id="calendartype" options={type}
        onChange={handleTypeSelectB}
        value={type.filter(function(option) {
          return option.value === selectedOptionB;
        })} placeholder="-- Select Calendar Type --" / >
          </div>
          </div>
          <div className="form-actions js-form-wrapper form-wrapper">
    <Button type="submit" className="button submit-button">
                    Search
                  </Button>
                  </div>
                  </fieldset>
                  </Form>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="band">
          <div className="wrapper">
            <div className="band__parts">
              <div className="band__part">
<h5>Disclaimer</h5>
<p>Some counties do not post and update criminal and civil calendars to this website. If you cannot find the calendar you are looking for or if you have any questions regarding the calendars for a particular county, please read the <a href="https://www.nccourts.gov/documents/local-rules-and-forms">local rules</a> for that county to determine which office maintains the calendars in question.
								</p>
                </div>
                </div>
                </div>
                </div>
                  </div>
                  </div>
  </article>
</main>
  )
}

export default Home;
